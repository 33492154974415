import {useEffect, useState} from "react";
import {Calculation, DimensionalImageDescriptor} from "../../business/core";
import AjaxService from "../../platform/ajax/AjaxService";
import {ResonanceCurveJson, ResonancesAtSpeedJson} from "../../business/useImpellerSimulation";


export type BladeManufacturingTypeCode = "standard" | "assg";

export const BLADE_MANUFACTURING_TYPES = [
    {typeCode: "standard", name: "Standard"},
    {typeCode: "assg", name: "AS/SG"}
];

export type Material = {
    code: string,
}

export interface ImpellerAssemblyCriteria {
    bladeCount: number,
    bladeManufacturing: "standard" | "assg",
    impellerDiameter: number,
    fanMountingPositionName: string,
    auditSeverityName: string,
    shaftDiameter: number | null,
    shaftLength: number | null,
    expectedImpellerHeight: number | null,
    bladeProfileDisplayName: string | null,
    bladeAngle: number | null,
    betaIncrementation: number | null,
    preferredConstructionMaterialCode: string
    preferredFastenersMaterialCode: string,
    rpm: number | null,
    bladeEndSpeed: number | null,
    powerAtWorkingPoint: number | null
    totalPressureAtWorkingPoint: number | null,
    softStartPresent: boolean | null,
    allowDemoData: boolean | null,
    allowTooLongClutch: boolean | null
}

export interface PartListEntryJson {
    name: string,
    pieceCount: number,
    pieceMass: number,
    materialName: string,
    standardName: string,
    priority: number,
    humanFriendlyTag: string
}

export function calculateMocowanieLopatMass( assembly : ImpellerAssemblyJson) : number {
    let mass = 0;
    mass += assembly.podpora.pieceMass * assembly.podpora.pieceCount;
    if ( assembly.nakladka !== null ) {
        mass += assembly.nakladka.pieceMass * assembly.nakladka.pieceCount;
    }
    if ( assembly.obejma !== null ) {
        mass += assembly.obejma.pieceMass * assembly.obejma.pieceCount;
    }
    return mass;
}

export interface ImpellerAssemblyJson {
    impellerDiameter: number,
    shaftLength: number,
    impellerHeight: number,
    clutch: {
        clutchTypeName: string,
        shaftHoleDiameter: number,
        clutchDisplayName: string,
        partDisplayName: string,
        materiaName: string
        drawingNumber: string
        mass: number,
        dlugoscSprzeglaH1: number,
        dlugoscWybieraniaH4: number,
        standardTolerancjiOtworuSprzegla: string,
        centralHoleDiameter: number,
        mountingScrewCount: number,
        glebokoscOsadzeniaTarczy: number,
        srednicaOtworuPodSrubeZabepieczajaca: number,
        srednicaGwintuDlaZestawuSrubowegoSt: number,
        dlugoscOtworuPasowaniaPodWalek: number,
        dlugoscPrzerwCzesciPasowaniaH3: number | null, // When h2/h3 is not exposed in name, then h3 is not calculated at all
        srednicaPodtrzymujacaSprzegloH4: number,
        momentDopuszczalny: number,
        srednicaZewnetrznaTuleiE: number,
        mForSecuringScrew: number,
        szerokoscWpustuK: number,
        otworZWpustemJ: number,
        h2Parameter: number,
        promienKolnierza: number
    },
    clutchToShaftFasteningElements: PartListEntryJson[],
    disc: {
        displayName: string,
        discDiameter: number,
        partDisplayName: string,
        materialName: string,
        standardName: string,
        mass: number,
        discThickness: number,
        aNaTarczy: number,
        korektaANaTarczy: number,
        srednicaOtworuCentralnego: number,
        srednicaOtworuD4: number,
        srednicaOtworuD5: number,
        mForClutchAndDiscFasteningScrew: number,
        mForDiscAndClampScrew: number
    },
    discToClutchFasteningElements: PartListEntryJson[],
    bladeMountConfigurationName: string,
    podpora: PartListEntryJson,
    nakladka: PartListEntryJson | null,
    obejma: PartListEntryJson | null,
    supportToDiscFasteningElements: PartListEntryJson[],
    bladeCount: number,
    blade: {
        displayName: string,
        partDisplayName: string,
        materialName: string,
        formName: string,
        formGeneralNumber: string,
        mass: number,
        pinDiameter: number,
        bladeProfileDisplayName: string,
        manufacturingTypeName: string,
        bladeAngle: number,
        zgrubnyPromienPolozeniaSrodkaMasyLopaty: number,
        promienPolozeniaSrodkaMasyLopaty: number,
        formBladeLength: number,
        vibrationFrequency: number,
        vibrationFrequencyEstimated: boolean,
        maximumVonMisesPinLoad: number | null,
        enforcementStrategySymbol: string
    },
    bladeClampingFasteningElements: PartListEntryJson[],
    wystawanieCzopaZPodpory: number,
    hubCap: {
        diameter: number,
        name: string,
        cutPresent: boolean,
        minimumAngleToApplyCut: number,
        thickness: number,
        standardName: string,
        materialName: string,
        mass: number
    } | null,
    hubCapFasteningElements: PartListEntryJson[],
    totalMass: number,
    assemblyPartList: {
        originalList: PartListEntryJson[],
        mergedList: PartListEntryJson[]
    },
    calculations: Calculation[],
    memoizedAsBlueprintId: string | null,
    temporaryIdentity: string
    geometryReport: GeometryReportJson
}

export interface GeometryReportJson {
    selectedAngleBoundingBox: BladeBoundingBoxAtAngle | null,
    measuredAngleBoundingBoxes: BladeBoundingBoxAtAngle[],
    mouldName: string,
    betaAngle: number
}

export interface BladeBoundingBoxAtAngle {
    bladeAngle: number,
    x1: number,
    x2: number,
    y1: number,
    y2: number
}

export interface MechanicalSelectionScoreJson {
    kryt1MoMsPassed: boolean,
    kryt2MbMsPassed: boolean,
    kryt3NrNdPassed: boolean,
    kryt4NrNzPassed: boolean,
    kryt1MoMsValue: number,
    kryt2MbMsValue: number,
    kryt3NrNdValue: number,
    kryt4NrNzValue: number,
    resonanceRfPassed: boolean,
    resonanceRfX2Passed: boolean,
    resonanceBpfPassed: boolean,
    vonMisesPinLoadPassed: boolean
}

export interface MechanicalSelectionChoiceJson {
    impellerAssembly: ImpellerAssemblyJson,
    dimensionalImageDescriptors: DimensionalImageDescriptor[],
    consideredBestPickInCurrentSelectionContext: boolean,
    consideredIgnoredBecauseBetterVariantExistInCurrentSelectionContext: boolean,
    mechanicalSelectionScore: MechanicalSelectionScoreJson | null,
    auditFailureReason: string | null,
    localId: string; // TODO rethink design
    resonanceReport: ResonanceReportJson | null
}

export interface MechanicalSelectionH3RangeEstimationJson {
    h3RangeEstimation: H3RangeEstimationJson,
    samples: {
        name: string,
        pinDiameter: number, // long
        shaftLength: number, // long
        formBladeLength: number, // double,
        clutchTypeName: string,
        range: H3RangeEstimationJson
    }[]
}

export interface H3RangeEstimationJson {
    sameSideGuess: H3RangeGuessJson,
    oppositeRangeGuess: H3RangeGuessJson
}

export interface H3RangeGuessJson {
    min: number,
    max: number
}

export interface ResonanceReportJson {
    resonanceCurve: ResonanceCurveJson,
    workingPointResonances: ResonancesAtSpeedJson
    estimated: boolean
}

export interface ErrorTreeNode {
    name: string,
    error: string | null,
    children: ErrorTreeNode[]
}

export interface ImpellerAssemblySearchResult {
    choices: MechanicalSelectionChoiceJson[],
    calculations: Calculation[],
    errorTree?: ErrorTreeNode,
    h3RangeEstimation: MechanicalSelectionH3RangeEstimationJson | null | "loading"
}

export default function useImpellerAssemblySearch(criteria: ImpellerAssemblyCriteria | undefined): { result: ImpellerAssemblySearchResult, loading: boolean } {

    const [result, setResult] = useState<ImpellerAssemblySearchResult>({calculations: [], choices: [], h3RangeEstimation: null, errorTree: {name: "", error: null, children: []}});
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        if (criteria === undefined) {
            return;
        }

        let cancelled = false;
        const callApi = async () => {
            setLoading(true);
            setResult({choices: [], calculations: [], h3RangeEstimation: "loading", errorTree: {name: "", error: null, children: []}});

            AjaxService.postJson("/api/impeller-assembly-search", criteria).then(result => {
                if (cancelled) {
                    return;
                }
                console.log("Received  response", result);
                const r = result.json as ImpellerAssemblySearchResult;
                r.choices.forEach(choice => choice.localId = "" + Math.random()) // TODO uuid?
                setResult(r);
            }).finally(() => {
                if (cancelled) {
                    return;
                }
                setLoading(false);
            });


            // axios.get<ImpellerAssemblySearchResult>("/api/impeller-assembly-search", {
            //     timeout: 5000,
            //     maxRedirects: 0,
            //     responseEncoding: "UTF-8",
            //     responseType: "json",
            //     params: criteria
            // }).then((axiosResponse: AxiosResponse<ImpellerAssemblySearchResult>) => {
            //     if ( cancelled ) {
            //         return;
            //     }
            //     console.log("Received Axios response", axiosResponse);
            //     if (axiosResponse.status < 200 || axiosResponse.status > 299) {
            //         throw new Error("Failed to fetch"); // TODO more error details
            //
            //     }
            //     setResult(axiosResponse.data);
            // }).finally(() => {
            //     if ( cancelled ) {
            //         return;
            //     }
            //     setLoading(false);
            // });
        }

        callApi();

        return () => {
            cancelled = true;
        }


    }, [criteria]);

    if (criteria === undefined) {
        return {result: {choices: [], calculations: [], h3RangeEstimation: null, errorTree: {name: "", error: null, children: []}}, loading};
    }

    return {
        result,
        loading
    };
}