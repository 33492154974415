import React, {useEffect} from 'react';
import UboldSpinner from "../../ubold-components/UboldSpinner";

import {Card} from "../../components/Card";
import {createAsyncResultsProviderViaApiWithGetParams, SearchResultsOrder, useSearch, useSelectFilterManager, useTextFilterManager} from "../../platform/search/PlatformSearch";
import {AdditionalSelectFilter, AdditionalTextFilter, CurrentlyUsedFilters, OrderSelector, SearchQueryBar} from "../../components/search/MetronicSearchComponents";
import {NavLink} from "react-router-dom";

export interface OptiselEventPayloadJson {
    typeCode: string
}

export type OptiselEventPayloadsNotLoaded = null;

export interface OptiselEventJson {
    id: string,
    creationMillis: number,
    type: string,
    typeDisplayName: string,
    discriminatorName: string,
    sourceSystemName: string,
    persistent: boolean,
    performerDisplayName: string,
    performerUserAccountIdentifier: string,
    caseIdentification: {
        inquiryNumber?: string,
        caseNumber?: string,
        mechanicalDesignIdentifier?: string
    },
    payloads: OptiselEventPayloadJson[] | OptiselEventPayloadsNotLoaded // TODO in future split into separate interfaces for short info vs full
}


export function OptiselEventHistoryPage(props: {}) {
    const queryFilterManager = useTextFilterManager("query", "Szukaj");
    const caseNumberFilterManager = useTextFilterManager("caseNumberFilter", "Numer sprawy");
    const inquiryNumberFilterManager = useTextFilterManager("inquiryNumberFilter", "Numer zapytania");
    const fanDesignIdentificatorFilterManager = useTextFilterManager("fanDesignIdentificatorFilter", "Identyfikator złożenia");
    const persistedOnlyFilterManager = useSelectFilterManager("persistedOnlyFilter", "Tylko utrwalone",[
        {label: "Nie", value: ""},
        {label: "Tak", value: "true"},
    ]);

    useEffect(()=>{

        persistedOnlyFilterManager.setCurrentValueExternally("true");
    },[]);
    // TODO "Persisted only"

    const availableOrders: SearchResultsOrder[] = [
        {id: "creation-desc", name: "Najnowsze wg czasu utworzenia", informationalDirection: "desc"},
        {id: "id-asc", name: "Identyfikator", informationalDirection: "asc"},
        {id: "name-asc", name: "Nazwa", informationalDirection: "asc"},
        {id: "case-number-asc", name: "Numer sprawy", informationalDirection: "asc"}
    ];

    const {
        currentlyActiveFilterManagers,
        refresh,
        isLoading,
        results,
        reset,
        currentOrderId,
        changeOrderId,
        paginationDriver: {
            currentPage,
            switchToNextPage,
            nextPageSwitchAvailable,
        }
    } = useSearch<any, OptiselEventJson>(
        createAsyncResultsProviderViaApiWithGetParams<OptiselEventJson>("/api/optisel-event-history/search"),
        [queryFilterManager, fanDesignIdentificatorFilterManager, caseNumberFilterManager, inquiryNumberFilterManager, persistedOnlyFilterManager],
        availableOrders,
        20);


    return <>
        <Card additionalClassNames={"card mb-4"}>
            <SearchQueryBar queryFilterManager={queryFilterManager} reset={reset} refresh={refresh}/>
            <div className="separator separator-dashed mt-6 mb-4"/>
            <div className="row">
                <div className="col-3">
                    <AdditionalTextFilter fanNameFilterManager={fanDesignIdentificatorFilterManager}/>
                </div>
                <div className="col-3">
                    <AdditionalTextFilter fanNameFilterManager={caseNumberFilterManager}/>
                </div>
                <div className="col-3">
                    <AdditionalTextFilter fanNameFilterManager={inquiryNumberFilterManager}/>
                </div>
                <div className="col-3">
                    <AdditionalSelectFilter filterManager={persistedOnlyFilterManager}/>
                </div>

            </div>
        </Card>
        <Card>
            <div className={"d-flex mb-2"}>
                <div className={"flex-grow-1"}>
                    <CurrentlyUsedFilters currentlyActiveFilterManagers={currentlyActiveFilterManagers}/>
                </div>
                <div className={"d-flex align-content-center"}>
                    <OrderSelector changeOrderId={changeOrderId} currentOrderId={currentOrderId} availableOrders={availableOrders}/>
                </div>
            </div>

            <div>
                {isLoading ? <div className="text-center mt-5 mb-5"><UboldSpinner/></div> : null}
            </div>
            {!isLoading && results.length === 0 && <div className="text-center fs-4 text-gray-600 mt-5 mb-5">Brak rezultatów</div>}

            {!isLoading && results.length > 0 && <div className={"table-responsive"}>
                <table className={"table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3"}>
                    <thead>
                    <tr className="fw-bolder text-muted">
                        <th className="w-100px">Wentylator / ID</th>
                        <th className="min-w-140px">Autor / Źródło</th>
                        <th className="min-w-120px"><div>Nr sprawy</div> <div>Nr zapytania</div> <div>Nr doboru</div></th>
                        <th className="min-w-120px">Utworzono</th>
                        <th className="text-end">Akcje</th>
                    </tr>
                    </thead>
                    <tbody>
                    {results.map(result => <tr className={result.persistent ? "" : "opacity-50"}>
                        <td className="align-top text-nowrap">
                            <div className="text-gray-600 fs-8">{result.typeDisplayName}</div>
                            <div className="fw-bold">{result.discriminatorName}</div>
                            <div className="text-gray-300 fs-8">{result.id}</div>
                        </td>
                        <td className="align-top">
                            <div>{result.performerDisplayName}</div>
                            <div className={"text-gray-400"}>{result.sourceSystemName}</div>
                        </td>
                        <td className="align-top">
                            <span className={"badge badge-secondary p-2 text-nowrap"}>{result.caseIdentification.inquiryNumber ?? ""}</span>
                            <span className={"badge badge-secondary p-2 text-nowrap"}>{result.caseIdentification.caseNumber ?? ""}</span>
                            <span className={"badge badge-secondary p-2 text-nowrap"}>{result.caseIdentification.mechanicalDesignIdentifier ?? ""}</span>
                        </td>
                        <td className="align-top">{new Date(result.creationMillis).toLocaleString("pl")}</td>
                        <td className="align-top text-end">
                            <NavLink to={`/optisel-event/${result.id}`} className={"btn btn-sm btn-light-primary"}>Zobacz</NavLink>
                        </td>
                    </tr>)}
                    </tbody>
                </table>

                {nextPageSwitchAvailable && <div>
                    <span className={"text-muted me-4"}>Strona {currentPage}</span>
                    <button className={"btn btn-outline btn-outline-dashed btn-sm"} onClick={switchToNextPage}>Szukaj więcej</button>
                </div>}
            </div>}
        </Card>
    </>;
}

