import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';

import FormComponentByDsl from "./FormComponentByDsl";
import {hasFormDslAnyError} from "./WebFormUtil";
import AjaxService from "../ajax/AjaxService";

export default function DynamicWebServiceDrivenForm(props) {

    const [formDsl, setFormDslHook] = useState(null);
    const [submitting, setSubmitting] = useState(false);
    const [initialDsl, setInitialDsl] = useState(null);
    const [loadFailed, setLoadFailed] = useState(false);

    function setFormDsl(dsl) {
        console.log("Setting new form dsl", dsl);
        setFormDslHook(dsl);
    }

    useEffect(() => {
        AjaxService.getJson(props.dslUrl)
            .then(r => {
                if (r.ok) {
                    setFormDsl(r.json);
                    setInitialDsl(r.json);
                } else {
                    throw new Error("HTTP request for form DSL failed, status: " + r.status);
                }
            })
            .catch(rejection => {
                console.error(rejection);
                setLoadFailed(true);
            })
    }, [props.dslUrl]);

    if (loadFailed) {
        return <div>Failed to load form</div>;
    }
    if (formDsl == null) {
        return <div>Loading...</div>;
    }

    function submit(e) {
        e.preventDefault();
        setSubmitting(true);
        let body = formDsl.value;

        if (props.valueSubmissionPreprocessor) {
            body = props.valueSubmissionPreprocessor(body);
        }

        AjaxService.postJson(props.submissionUrl, body)
            .then(x => new Promise(resolve => setTimeout(() => resolve(x), 1000)))
            .then(response => {
                if (hasFormDslAnyError(response.json)) {
                    setFormDsl({...formDsl, errors: response.json.errors});
                } else {
                    if (response.status >= 400) {
                        alert("Sorry, error ocurred");
                    } else {
                        if (props.clearOnSuccess === true) {
                            setFormDsl(initialDsl);
                        }
                        props.successHandler(response.json);
                    }
                }
            }).finally(() => {
            setSubmitting(false);
        });
    }

    return <form>
        <FormComponentByDsl dsl={formDsl} onDslChange={setFormDsl}/>
        <div className="row mb-0 mt-2 justify-content-end">
            <div className="col-8">
                <button type="submit" className="btn btn-primary btn-rounded" disabled={submitting || props.forceDisabled} onClick={submit}>
                    {props.submitLabel || "Save"}
                    {(submitting ? <div className="spinner-border spinner-border-sm ms-3"></div> : null)}
                </button>
            </div>
        </div>
    </form>;
}

DynamicWebServiceDrivenForm.propTypes = {
    dslUrl: PropTypes.string,
    submissionUrl: PropTypes.string,
    successHandler: PropTypes.func,
    valueSubmissionPreprocessor: PropTypes.func
}
