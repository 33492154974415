import React, {ReactNode, useEffect, useState} from "react";
import {Editor} from "../../form/Editor";
import {MeasurementStandard} from "../../platform/measurement/MeasurementStandard";
import {OptionalEditor} from "../../form/editors/OptionalEditor";
import {createEditorForMeasurementStandard, MeasureEditor, MeasureEditorValue} from "../../form/editors/MeasureEditor";
import {useEditor} from "../../form/EditorReact";
import {MetronicMeasureEditorDummyWithButton} from "../../form/metronic/MetronicMeasureEditor";
import {Card} from "../../components/Card";
import {PressureCorrector} from "../../business/ImpellerSimulationCriteria";
import {BladeProfileJson} from "../profile/BladeProfileService";
import {Material} from "../../business/mechanical/Material";
import {createInternationalizationPrefixer, i18n} from "../../platform/i18n/i18n";
import {OneOfEditor, SelectionKey, SelectionOption} from "../../form/editors/OneOfEditor";
import {FAN_APPLICATION_TYPES_PROVIDER} from "../../business/flow/FanApplicationTypes";
import {MetronicMultiButtonOneOfEditor, MetronicMultiButtonOneOfEditorXxs} from "../../form/metronic/MetronicMultiButtonOneOfEditor";
import {WentechMeasuringStandards} from "../../business/measurement/WentechMeasurementStandards";
import {useDiffuserEditor} from "../../business/editor/useDiffuserEditor";
import {useCrosswindEditor} from "../../business/editor/useCrosswindEditor";
import {ManyOfEditor} from "../../form/editors/ManyOfEditor";
import {MetronicMultiButtonManyOfEditorXxs} from "../../form/metronic/MetronicMultiButtonManyOfEditor";
import {useObstacleParamsEditor} from "../../business/editor/useObstacleParamsEditor";
import {AdjustableImpellerOverviewJson} from "../impeller/AdjustableImpellerService";
import {MetronicOneOfEditor} from "../../form/metronic/MetronicOneOfEditor";
import {InstallationSpecificationJson, ManufacturingSpecificationJson, MechanicalPreferencesJson, SelectionRequestJson, SimulationRequestJson} from "../../business/ApiJsonTypes";
import {DimensionRestrictions, useDimensionsEditorFacade} from "../../business/editor/useDimensionsEditorFacade";
import {BladeManufacturingTypeCode} from "../mechanical/ImpellerAssemblyService";
import {AirDensityCalculator} from "../../components/AirDensityCalculator";
import {KILOGRAM_PER_CUBIC_METER_BASE_UNIT} from "../../business/measurement/WentechMeasurementUnits";
import {SelectionCriteriaDownloader} from "./criteriaexport/SelectionCriteriaDownloader";
import {SelectionCriteriaUploader} from "./criteriaexport/SelectionCriteriaUploader";
import {useSpeedCriteriaEditor} from "./useSpeedCriteriaEditor";
import {useSpeedEditorFacade} from "../../business/editor/useSpeedEditorFacade";


export enum SelectionCriteriaVariant {
    /**
     * Criterias for generic selection process
     * - no ability to pick specific fan
     * - blade angle as minor parameter "mixed" into other details
     */
    GENERIC_FAN_SELECTION,
    /**
     * Criterias for simulating specific fan
     * - Specific fan selection as the primary control
     * - Blade angle as the second most importance control (and no autoselection for blade angle)
     */
    SPECIFIC_FAN_SIMULATION
}

/**
 * Identifies a base (adjustable impeller + angle) for possible fans that may differ in some
 * construction and environmental details, but basically specifies which adjustable impeller and which
 * psi/fi characteristics will be used for simulation (kind of fan identification, but still under this reference
 * numerous fans can be constructed) it's more like adjustable-impeller-id-on-specific-adjust-option-identifier
 */
export interface SimulationFanAdjustmentReference {
    adjustableImpellerId: string,
    bladeAngle: number
}

const t = createInternationalizationPrefixer("FanSelectionCriteriaControls");

/**
 * Single for with some variant specification strategies (variant) can be used for simulation and for selection.
 * When used for simulation, it will only populate the result of simulationRequestJson
 * but when selection variant is used, it will only populate the result of selectionRequestJson
 * Should nto populate both, because e.g. selection does not need impeller sleection, where simulation must have choosen impeller
 */
export function useFanCriteria(availableInletShapes: PressureCorrector[],
                               availableProfiles: BladeProfileJson[],
                               availableMaterials: Material[],
                               availableShaftDiameters: number[],
                               adjustableImpellerOverviews: AdjustableImpellerOverviewJson[],
                               variant: SelectionCriteriaVariant,
                               prepopulationSimulationRequest: SimulationRequestJson | null): {
    formElement: JSX.Element,
    selectionRequestJsonOrIncorrect: SelectionRequestJson | "incorrect-request",
    simulationRequestJson: SimulationRequestJson | "incorrect-request",
} {

    const [collapsed, setCollapsed] = useState(prepopulationSimulationRequest !== null);
    const [prepopulationSelectionRequest, setPrepopulationSelectionRequest] = useState<SelectionRequestJson | null>(null);
    const [stabilizationUnlocked, setStabilizationUnlocked] = useState(false);
    const [prepopulationDone, setPrepopulationDone] = useState(false);

    useEffect(() => {
        const storedPreferredCriteria = sessionStorage.getItem("preferredSelectionCriteria");
        console.log("Checking for stored criteria preference");
        if (storedPreferredCriteria !== null) {
            if (prepopulationSimulationRequest === null) {
                console.log("Detected prefered criteria (without URL predefined ones), reviving");
                setPrepopulationSelectionRequest(JSON.parse(storedPreferredCriteria))
            } else {
                console.log("Criteria preference found in storage, but predefined given - not reviving preference");
            }
        }
    }, []);

    const adjustableImpellerIdEditor = useEditor<OptionalEditor<SelectionKey>>(() => new OptionalEditor<SelectionKey>(
        new OneOfEditor(
            adjustableImpellerOverviews.map(overview => ({key: overview.id, label: overview.diameterAgnosticName})),
            MetronicOneOfEditor
        ),
        variant === SelectionCriteriaVariant.SPECIFIC_FAN_SIMULATION,
        () => <></>,
        (DelegatedEditorComponent) => <DelegatedEditorComponent/>
    ));

    // TODO reduce noise, extract method
    const angleOptions: number[] = [];
    if (adjustableImpellerIdEditor.value !== null) {
        for (const adjustbleImpellerOverview of adjustableImpellerOverviews) {
            if (adjustbleImpellerOverview.id === adjustableImpellerIdEditor.value) {
                for (const adjustOption of adjustbleImpellerOverview.impellerAdjustOptions) {
                    angleOptions.push(adjustOption.bladeAngle);
                }
            }
        }
    }
    if (angleOptions.length === 0) {
        angleOptions.push(0); // TODO improve design, theoretically never accessible for user due to hiding not enabled editor, but still dirty
    }


    const toFixedAngleOptions = angleOptions.map(angleOption => ({
        key: angleOption.toFixed(1),
        label: angleOption.toFixed(1)
    }));

    const simulationBladeAngleEditor = useEditor<OptionalEditor<SelectionKey>>(() => new OptionalEditor<SelectionKey>(
        new OneOfEditor(
            toFixedAngleOptions,
            MetronicOneOfEditor
        ),
        variant == SelectionCriteriaVariant.SPECIFIC_FAN_SIMULATION,
        () => <></>,
        (DelegatedEditorComponent) => <DelegatedEditorComponent/>
    ), [JSON.stringify(angleOptions)], "simulationBladeAngleEditor");


    const applicationTypeEditor = useEditor<OneOfEditor>(() => new OneOfEditor(
        FAN_APPLICATION_TYPES_PROVIDER().map(t => ({key: t.id, label: t.name}))
        , MetronicOneOfEditor.withAdditionalClassString("extra-wide")));

    const operationalSetupEditor = useEditor<OneOfEditor>(() => new OneOfEditor([
        // TODO Warning, duplication
        {key: "INDUCED_DRAFT", label: t("Ssący")},
        {key: "FORCED_DRAFT", label: t("Tłoczący")},
    ], MetronicMultiButtonOneOfEditor));

    const airDensityEditor = useEditor(() => createEditorForMeasurementStandard(WentechMeasuringStandards.AIR_DENSITY));
    const inletTemperatureEditor = useEditor(() => createEditorForMeasurementStandard(WentechMeasuringStandards.AIR_TEMPERATURE));

    const diffuserEditor = useDiffuserEditor(operationalSetupEditor.value === "INDUCED_DRAFT");
    diffuserEditor.meta = t("Dyfuzor");

    useEffect(() => {
        if (operationalSetupEditor.value === "FORCED_DRAFT") {
            diffuserEditor.disable();
        }
    }, [operationalSetupEditor.value]);

    const crosswindEditor = useCrosswindEditor();
    crosswindEditor.meta = t("Wiatr poprzeczny");
    const inletObstacleParamsEditor = useObstacleParamsEditor();
    inletObstacleParamsEditor.meta = t("Przeszkoda na wlocie");
    const outletObstacleParamsEditor = useObstacleParamsEditor();
    outletObstacleParamsEditor.meta = t("Przeszkoda na wylocie");

    const environmentalOptionalEditors: OptionalEditor<any>[] = [diffuserEditor, crosswindEditor, inletObstacleParamsEditor, outletObstacleParamsEditor];

    const airFlowEditor = useEditor(() => createEditorForMeasurementStandard(WentechMeasuringStandards.AIR_FLOW));
    const staticPressureEditor = useEditor(() => createEditorForMeasurementStandard(WentechMeasuringStandards.IMPELLER_PRESSURE));

    const staticPressureSubjectEditor = useEditor<OneOfEditor>(() => new OneOfEditor([
        {key: "INSTALLATION", label: t("Zadano ciśnienie potrzebne (instalacji)")},
        {key: "FAN_ONLY", label: t("Zadano ciśnienie samego wentylatora")},
    ], MetronicMultiButtonOneOfEditor));

    const bladeManufacturingTypeEditor = useEditor<OneOfEditor>(() => new OneOfEditor([
        {key: "standard", label: t("Standard")}, // TODO reduce duplication agains BLADE_MANUFACTURING_TYPES constant
        {key: "assg", label: t("AS/SG")},
    ], MetronicMultiButtonOneOfEditor));


    const dimensionsEditorFacade = useDimensionsEditorFacade(DimensionRestrictions.createForManufacturing(bladeManufacturingTypeEditor.value as BladeManufacturingTypeCode));
    const speedEditorFacade = useSpeedEditorFacade(dimensionsEditorFacade.dimensionDetails?.impellerDiameter);


    const inletShapeEditor = useEditor<OneOfEditor>(() => new OneOfEditor(
        availableInletShapes.map((availableShape): SelectionOption => ({
            key: availableShape.id,
            label: i18n(availableShape.humanFriendlyNameIk)
        })), undefined, "ellipse-hd015"));

    const bladeAngleEditor = useOptionalMeasurementEditor(
        WentechMeasuringStandards.BLADE_ANGLE,
        false);


    const bladeProfilesEditor = useEditor<OptionalEditor<SelectionKey[]>>(
        () => new OptionalEditor<SelectionKey[]>(
            new ManyOfEditor(
                availableProfiles.map((availableProfile): SelectionOption => ({
                    key: availableProfile.name,
                    label: availableProfile.displayName
                })), MetronicMultiButtonManyOfEditorXxs, availableProfiles.map(p => p.name)
            ),
            variant == SelectionCriteriaVariant.GENERIC_FAN_SELECTION,
            () => <></>,
            (DelegatedEditorComponent) => <DelegatedEditorComponent/>
        ));

    const preferredConstructionMaterialEditor = useEditor<OneOfEditor>(() => new OneOfEditor(
        availableMaterials.map(mat => ({
            key: mat.code,
            label: mat.shorName
        })), MetronicMultiButtonOneOfEditorXxs, "HDG"));

    const preferredFastenersMaterialEditor = useEditor<OneOfEditor>(() => new OneOfEditor(
        availableMaterials.map(mat => ({
            key: mat.code,
            label: mat.shorName
        })), MetronicMultiButtonOneOfEditorXxs, "HDG"));

    const shaftDiameterEditor = useOptionalOneOfEditor(availableShaftDiameters.map(asd => ({key: "" + asd, label: asd + " mm"})), "" + availableShaftDiameters[0], false);
    const shaftLengthEditor = useOptionalMeasurementEditor(WentechMeasuringStandards.SHAFT_LENGTH, false);
    const impellerHeightEditor = useOptionalMeasurementEditor(WentechMeasuringStandards.IMPELLER_HEIGHT, false);

    const fanMountingOrientationEditor = useEditor<OneOfEditor>(() => new OneOfEditor([
        {key: "HORIZONTAL", label: t("Poziomo  (wał pionowy)")},
        {key: "VERTICAL", label: t("Pionowo (wał poziomy)")},
    ], MetronicMultiButtonOneOfEditorXxs));
    const airflowRelativeClutchMountingSideEditor = useEditor<OneOfEditor>(() => new OneOfEditor([
        {key: "CLUTCH_ON_INLET", label: t("Sprzęgło na wlocie")},
        {key: "CLUTCH_ON_OUTLET", label: t("Sprzęgło na wylocie")},
    ], MetronicMultiButtonOneOfEditorXxs));
    const supportAndClutchDiscSideEditor = useEditor<OneOfEditor>(() => new OneOfEditor([
        {key: "SUPPORTS_AND_CLUTCH_OPPOSITE_SIDES", label: t("Przeciwne strony")},
        {key: "SUPPORTS_AND_CLUTCH_SAME_SIDE", label: t("Ta sama strona")},
    ], MetronicMultiButtonOneOfEditorXxs));
    const softStartPresentEditor = useEditor<OneOfEditor>(() => new OneOfEditor([
        {key: "false", label: t("Brak")},
        {key: "true", label: t("Obecny")},
    ], MetronicMultiButtonOneOfEditorXxs));
    const realizeWithClutchEditor = useEditor<OneOfEditor>(() => new OneOfEditor([
        {key: "true", label: t("Ze sprzęgłem")},
        {key: "false", label: t("Bez sprzęgła")},
    ], MetronicMultiButtonOneOfEditorXxs));

    const allEditorsExceptDimensionsAndSpeed: Editor<any>[] = [adjustableImpellerIdEditor,
        simulationBladeAngleEditor,
        applicationTypeEditor,
        operationalSetupEditor,
        airDensityEditor,
        inletTemperatureEditor,
        diffuserEditor,
        crosswindEditor,
        inletObstacleParamsEditor,
        outletObstacleParamsEditor,
        airFlowEditor,
        staticPressureEditor,
        staticPressureSubjectEditor,
        inletShapeEditor,
        bladeAngleEditor,
        bladeProfilesEditor,
        bladeManufacturingTypeEditor,
        preferredConstructionMaterialEditor,
        preferredFastenersMaterialEditor,
        shaftDiameterEditor,
        shaftLengthEditor,
        impellerHeightEditor,
        fanMountingOrientationEditor,
        airflowRelativeClutchMountingSideEditor,
        supportAndClutchDiscSideEditor,
        softStartPresentEditor,
        realizeWithClutchEditor];

    const reset = () => {
        console.log("Clearing prefered criteria")
        sessionStorage.removeItem("preferredSelectionCriteria");
        allEditorsExceptDimensionsAndSpeed.forEach(editor => editor.reset());
        dimensionsEditorFacade.reset();
        speedEditorFacade.reset();
    };

    const prepopulateSimulationAndSelectionCommonParts = (installationSpecification: InstallationSpecificationJson,
                                                          mechanicalPreferences: MechanicalPreferencesJson,
                                                          manufacturingSpecification: ManufacturingSpecificationJson) => {
        applicationTypeEditor.populate(installationSpecification.fanApplicationTypeCode);
        operationalSetupEditor.populate(installationSpecification.operationalSetupCode);
        if (installationSpecification.diffuserDetails !== null) {
            diffuserEditor.populate(installationSpecification.diffuserDetails);
            diffuserEditor.enable();
        } else {
            diffuserEditor.disable();
        }
        if (installationSpecification.crosswindDetails !== null) {
            crosswindEditor.populate(installationSpecification.crosswindDetails);
            crosswindEditor.enable();
        } else {
            crosswindEditor.disable();
        }


        if (installationSpecification.inletObstacleParameters !== null) {
            inletObstacleParamsEditor.populate({
                obstacleDistanceToHousingDiameter: installationSpecification.inletObstacleParameters.obstacleDistanceToHousingDiameter_LToD,
                obstacleToImpellerArea: installationSpecification.inletObstacleParameters.obstacleToImpellerArea_ApToAc
            });
            inletObstacleParamsEditor.enable();
        } else {
            inletObstacleParamsEditor.disable();
        }
        if (installationSpecification.outletObstacleParameters !== null) {
            outletObstacleParamsEditor.populate({
                obstacleDistanceToHousingDiameter: installationSpecification.outletObstacleParameters.obstacleDistanceToHousingDiameter_LToD,
                obstacleToImpellerArea: installationSpecification.outletObstacleParameters.obstacleToImpellerArea_ApToAc
            });
            outletObstacleParamsEditor.enable();
        } else {
            outletObstacleParamsEditor.disable();
        }
        inletShapeEditor.populate(installationSpecification.inletShapeId);

        if (mechanicalPreferences.shaftDiameter !== null) {
            shaftDiameterEditor.populate(mechanicalPreferences.shaftDiameter.toFixed(0));
            shaftDiameterEditor.enable();
        } else {
            shaftDiameterEditor.disable();
        }
        if (mechanicalPreferences.shaftLength !== null) {
            shaftLengthEditor.populate(mechanicalPreferences.shaftLength);
            shaftLengthEditor.enable();
        } else {
            shaftLengthEditor.disable();
        }
        if (mechanicalPreferences.expectedImpellerHeight !== null) {
            impellerHeightEditor.populate(mechanicalPreferences.expectedImpellerHeight);
            impellerHeightEditor.enable();
        } else {
            impellerHeightEditor.disable();
        }
        // TODO preopolulations below are suspicious because basically form ALWAYS require some value, where request allows to not specify.
        if (mechanicalPreferences.fanMountingOrientationCode != null) {
            fanMountingOrientationEditor.populate(mechanicalPreferences.fanMountingOrientationCode);
        }
        if (mechanicalPreferences.airflowRelativeClutchMountingSideCode != null) {
            airflowRelativeClutchMountingSideEditor.populate(mechanicalPreferences.airflowRelativeClutchMountingSideCode);
        }
        if (mechanicalPreferences.preferredSupportAndClutchDiscSideCode != null) {
            supportAndClutchDiscSideEditor.populate(mechanicalPreferences.preferredSupportAndClutchDiscSideCode);
        }

        bladeManufacturingTypeEditor.populate(manufacturingSpecification.bladeManufacturingTypeCode);
        preferredConstructionMaterialEditor.populate(manufacturingSpecification.materialPreference.preferredConstructionMaterialCode);
        preferredFastenersMaterialEditor.populate(manufacturingSpecification.materialPreference.preferredFastenersMaterialCode);
    }

    useEffect(function prepopulateCriteriaControlsForSimulation() {
        if (prepopulationSimulationRequest == null) {
            return;
        }
        prepopulateSimulationAndSelectionCommonParts(
            prepopulationSimulationRequest.installationSpecification,
            prepopulationSimulationRequest.mechanicalPreferences,
            prepopulationSimulationRequest.manufacturingSpecification);

        adjustableImpellerIdEditor.populate(prepopulationSimulationRequest.fanFlowSpecification.adjustableImpellerId);
        simulationBladeAngleEditor.populate(prepopulationSimulationRequest.fanFlowSpecification.bladeAngle.toFixed(1));

        airFlowEditor.populate(prepopulationSimulationRequest.demoAirFlow);
        airDensityEditor.populate(prepopulationSimulationRequest.fanFlowSpecification.fluidSpecification.density);
        inletTemperatureEditor.populate(prepopulationSimulationRequest.fanFlowSpecification.fluidSpecification.temperature);
        if (prepopulationSimulationRequest.fanFlowSpecification.rpm !== null) {
            speedEditorFacade.prepopulate({rpm: prepopulationSimulationRequest.fanFlowSpecification.rpm});
        } else {
            speedEditorFacade.prepopulate("speed-autoselection");
        }
        dimensionsEditorFacade.prepopulate({
            bladeTipClearanceRatio: prepopulationSimulationRequest.fanFlowSpecification.fanDiameters.gap, // TODO unify "gap" vs "clearance" nomenclature
            impellerDiameter: prepopulationSimulationRequest.fanFlowSpecification.fanDiameters.impellerDiameter
        });
        speedEditorFacade.prepopulate({
            rpm: prepopulationSimulationRequest.fanFlowSpecification.rpm,
        })
        bladeProfilesEditor.populate([]);
        bladeProfilesEditor.disable();
        softStartPresentEditor.populate(prepopulationSimulationRequest.softStartPresent ? "true" : "false");
        realizeWithClutchEditor.populate(prepopulationSimulationRequest.realizeWithClutch ? "true" : "false");
        setPrepopulationDone(true);
    }, [JSON.stringify(prepopulationSimulationRequest)]);

    useEffect(function prepopulateCriteriaControlsForSelection() {
        if (prepopulationSelectionRequest == null) {
            return;
        }
        prepopulateSimulationAndSelectionCommonParts(
            prepopulationSelectionRequest.installationSpecification,
            prepopulationSelectionRequest.mechanicalPreferences,
            prepopulationSelectionRequest.manufacturingSpecification);

        if (prepopulationSelectionRequest.installationWorkingPointQuery != null) {
            staticPressureSubjectEditor.populate("INSTALLATION");
            if (prepopulationSelectionRequest.installationWorkingPointQuery.requestedInstallationAirFlow != null) {
                airFlowEditor.populate(prepopulationSelectionRequest.installationWorkingPointQuery.requestedInstallationAirFlow);
            }
            if (prepopulationSelectionRequest.installationWorkingPointQuery.requestedInstallationStaticPressure != null) {
                staticPressureEditor.populate(prepopulationSelectionRequest.installationWorkingPointQuery.requestedInstallationStaticPressure)
            }
        }
        if (prepopulationSelectionRequest.fanWorkingPointQuery != null) {
            staticPressureSubjectEditor.populate("FAN_ONLY");
            if (prepopulationSelectionRequest.fanWorkingPointQuery.requestedFanAirFlow != null) {
                airFlowEditor.populate(prepopulationSelectionRequest.fanWorkingPointQuery.requestedFanAirFlow);
            }
            if (prepopulationSelectionRequest.fanWorkingPointQuery.requestedFanStaticPressure != null) {
                staticPressureEditor.populate(prepopulationSelectionRequest.fanWorkingPointQuery.requestedFanStaticPressure)
            }
        }

        airDensityEditor.populate(prepopulationSelectionRequest.fanFlowPreferences.fluidSpecification.density);
        inletTemperatureEditor.populate(prepopulationSelectionRequest.fanFlowPreferences.fluidSpecification.temperature);
        if (prepopulationSelectionRequest.fanFlowPreferences.requestedRpm !== null) {
            speedEditorFacade.prepopulate({rpm: prepopulationSelectionRequest.fanFlowPreferences.requestedRpm});
        } else {
            speedEditorFacade.prepopulate("speed-autoselection");
        }
        if (prepopulationSelectionRequest.fanFlowPreferences.requestedFanDiameters != null) {
            dimensionsEditorFacade.prepopulate({
                bladeTipClearanceRatio: prepopulationSelectionRequest.fanFlowPreferences.requestedFanDiameters.gap, // TODO unify "gap" vs "clearance" nomenclature
                impellerDiameter: prepopulationSelectionRequest.fanFlowPreferences.requestedFanDiameters.impellerDiameter
            });
        }

        // Here is some complexity because simulation do not display profile editor, but still editing criteria on simulation may store
        // nulled profile valuies. In past this led to error that profile selector dissapear from regular fan selection.
        // In case imsulation set preference for profiles to none/null. we can reset on selection to show all, but still we need to .enable
        // as enablement likely also may be persisted in storage
        if (prepopulationSelectionRequest.fanFlowPreferences.requestedBladeProfileNames != null) {
            if (prepopulationSelectionRequest.fanFlowPreferences.requestedBladeProfileNames.length === 0) {
                bladeProfilesEditor.reset();
            } else {
                bladeProfilesEditor.populate(prepopulationSelectionRequest.fanFlowPreferences.requestedBladeProfileNames);
            }
        } else {
            bladeProfilesEditor.reset();
            bladeProfilesEditor.enable();
        }


        softStartPresentEditor.populate(prepopulationSelectionRequest.softStartPresent ? "true" : "false");
        realizeWithClutchEditor.populate(prepopulationSelectionRequest.realizeWithClutch ? "true" : "false");
    }, [JSON.stringify(prepopulationSelectionRequest)]);


    /*
    This is a bit dirty fix for the impeller/angle switcher lifecycle.
    preopopulation of the angle cannot be done together with prepopulation of selected impeller, because many things happen between -
    selecting impeller causes re-redner which causes angleOptions to be recalculated and to recreate the angle selection.
    So, there was a defect that prepopulating angle selection was discarded on the begining, because it had still old options for first impeller selectable on list.
    'simulationBladeAngleEditor' dependency is added so that preopulating impeller in hook above, will recalculate angle options, which will re-create the angle editor,
    and this ill re-triger the effect below to properly select angle.
    (the case was: first impeller on list has 12+ angles, but the prepopulated tries to select 9.8 which is initially not existent on the list)
    Side effect: re-selecting same fan as in prepopuylation again, will pick the prepopulation angle instead of first angle on the list\
     */
    useEffect(function prepopulateCriteriaControlsForSimulation() {
        if (prepopulationSimulationRequest == null) {
            return;
        }
        simulationBladeAngleEditor.populate(prepopulationSimulationRequest.fanFlowSpecification.bladeAngle.toFixed(1));
    }, [JSON.stringify(prepopulationSimulationRequest), simulationBladeAngleEditor])


    const actualForm = <div style={{display: "flex", flexDirection: "column", gap: "10px"}}>
        {adjustableImpellerIdEditor.enabled && simulationBladeAngleEditor.enabled && <CriteriaCard title={t("Wentylator")}>
            <Row>
                <ColumnHalf>
                    <FlexedLabeledEditor label={t("Wirnik")} editor={adjustableImpellerIdEditor}/>
                </ColumnHalf>
                <ColumnHalf>
                    <FlexedLabeledEditor label={t("Kąt (nastaw)")} editor={simulationBladeAngleEditor}/>
                </ColumnHalf>
            </Row>
        </CriteriaCard>}
        <CriteriaCard title={t("Aplikacja")}>
            <Row>
                <div className={"col-6"}><FlexedLabeledEditor label={t("Typ aplikacji")} editor={applicationTypeEditor}
                                                              leftCols={4} rightCols={8}/></div>
                <div className={"col-6"}><FlexedLabeledEditor label={t("Układ pracy")} editor={operationalSetupEditor}/>
                </div>
            </Row>
        </CriteriaCard>
        <CriteriaCard title={t("Punkt pracy")}>
            <Row>
                <ColumnHalf>
                    <LabeledEditor label={t("Wydajność")} editor={airFlowEditor}/>
                </ColumnHalf>
            </Row>
            {(variant == SelectionCriteriaVariant.GENERIC_FAN_SELECTION) ?
                <Row>
                    <ColumnHalf>
                        <LabeledEditor label={t("Ciśnienie statyczne")} editor={staticPressureEditor}/>
                    </ColumnHalf>
                    <ColumnHalf>
                        {staticPressureSubjectEditor.Component()}
                    </ColumnHalf>
                </Row> :
                <div className={"text-gray-400 fs-7 text-center"}>{t("Symulacja - ciśnienie zostanie obliczone.")}</div>}
        </CriteriaCard>
        <CriteriaCard title={t("Środowisko")}>
            <Row>
                <ColumnHalf>
                    <LabeledEditorlike label={t("Gęstość czynnika")}>
                        <div className={"d-flex gap-3"}>
                            {airDensityEditor.Component()}
                            <button className={"btn btn-xxs btn-outline btn-outline-success"} onClick={() => {
                                airDensityEditor.toggleNumericEditorEditability()
                            }}>{airDensityEditor.numericEditorReadOnly ? t("Zadaj") : t("Oblicz")}</button>
                        </div>
                    </LabeledEditorlike>
                    {airDensityEditor.numericEditorReadOnly && <AirDensityCalculator airTemperature={inletTemperatureEditor.value} onSuccessfulSubmission={(calculatedDensity) => {
                        airDensityEditor.populate(calculatedDensity);
                    }}/>}
                </ColumnHalf>
                <ColumnHalf><LabeledEditor label={t("Temperatura wlotowa")} editor={inletTemperatureEditor}/></ColumnHalf>
            </Row>
            <Row>
                <div className={"col-12 mt-4"} style={{display: "flex", justifyContent: "center", gap: "10px"}}>
                    {environmentalOptionalEditors.map(editor => (
                        <button key={editor.meta}
                                className={"btn btn-xxs  " + (editor.enabled ? "btn-primary" : "btn-outline btn-outline-primary")}
                                disabled={editor === diffuserEditor && operationalSetupEditor.value === "FORCED_DRAFT"}
                                onClick={editor.toggleEnablement}><i
                            className={"fas " + (!editor.enabled ? "fa-plus" : "fa-minus-circle")}></i> {editor.meta}
                        </button>
                    ))}
                </div>
            </Row>
            <Row>
                {environmentalOptionalEditors.filter(editor => editor.enabled).map(editor => <ColumnHalf
                    key={editor.meta}><AspectSectionHeader title={editor.meta}/>{editor.Component()}</ColumnHalf>)}
            </Row>
        </CriteriaCard>
        <Row>
            <ColumnHalf>
                <CriteriaCard title={t("Konstrukcja")}>
                    {dimensionsEditorFacade.editorComponent}
                    <div className="separator mb-3 mt-3"/>
                    {speedEditorFacade.editorComponent}
                    <LabeledEditor label={t("Kształt wlotu")} editor={inletShapeEditor}/>
                    {(bladeAngleEditor.enabled || variant == SelectionCriteriaVariant.GENERIC_FAN_SELECTION) &&
                        <LabeledEditor label={t("Kąt łopat")} editor={bladeAngleEditor}/>}
                    {bladeProfilesEditor.enabled &&
                        <LabeledEditor label={t("Profil łopaty")} editor={bladeProfilesEditor}/>}
                </CriteriaCard>
            </ColumnHalf>
            <ColumnHalf>
                <CriteriaCard title={t("Szczegóły mechaniczne")}>
                    <LabeledEditor label={t("Wykonanie łopat")} editor={bladeManufacturingTypeEditor}/>
                    <LabeledEditor label={t("Materiał konstrukcji")} editor={preferredConstructionMaterialEditor}/>
                    <LabeledEditor label={t("Materiał części złącznych")} editor={preferredFastenersMaterialEditor}/>

                    <LabeledEditor label={t("Średnica wału")} editor={shaftDiameterEditor}/>
                    <LabeledEditor label={t("Długość wału")} editor={shaftLengthEditor}/>
                    <LabeledEditor label={t("Wysokość wirnika")} editor={impellerHeightEditor}/>

                    <LabeledEditor label={t("Kierunek wirnika")} editor={fanMountingOrientationEditor}/>
                    <LabeledEditor label={t("Wykonanie ze sprzęgłem")} editor={realizeWithClutchEditor}/>
                    <LabeledEditor label={t("Pozycja sprzęgła")} editor={airflowRelativeClutchMountingSideEditor}/>
                    <LabeledEditor label={t("Podpory i sprzęgło wzgl. tarczy")} editor={supportAndClutchDiscSideEditor}/>
                    <LabeledEditor label={t("Miękki start")} editor={softStartPresentEditor}/>

                </CriteriaCard>
            </ColumnHalf>
        </Row>
    </div>;


    let selectionRequestJson: SelectionRequestJson | "incorrect-request" = "incorrect-request";
    let simulationRequestJson: SimulationRequestJson | "incorrect-request" = "incorrect-request";

    if (airDensityEditor.value != null &&
        inletTemperatureEditor.value != null &&
        dimensionsEditorFacade.dimensionDetails != null &&
        speedEditorFacade.speedDetails !== "invalid-due-to-incorrect-impeller-diameter" &&
        (staticPressureEditor.value != null) &&
        (!shaftDiameterEditor.enabled || shaftDiameterEditor.value != null) && // For both simulation & selection, shaft values can be null if editor disabled (otherways is parsing error)
        (!shaftLengthEditor.enabled || shaftLengthEditor.value != null) &&
        (!impellerHeightEditor.enabled || impellerHeightEditor.value != null)) {

        if ((airFlowEditor.value != null) &&
            (!bladeAngleEditor.enabled || bladeAngleEditor.value != null)) {

            selectionRequestJson = {
                fanFlowPreferences: {
                    fluidSpecification: {
                        density: airDensityEditor.value,
                        temperature: inletTemperatureEditor.value
                    },
                    requestedBladeAngle: bladeAngleEditor.value,
                    requestedFanDiameters: {
                        gap: dimensionsEditorFacade.dimensionDetails.bladeTipClearanceRatio,
                        impellerDiameter: dimensionsEditorFacade.dimensionDetails.impellerDiameter
                    },
                    requestedBladeProfileNames: bladeProfilesEditor.value,
                    requestedRpm: speedEditorFacade.speedDetails === "speed-autoselection" ? null : speedEditorFacade.speedDetails.rpm
                },
                fanWorkingPointQuery: staticPressureSubjectEditor.value === "INSTALLATION" ? null : {
                    requestedFanAirFlow: airFlowEditor.value,
                    requestedFanStaticPressure: staticPressureEditor.value
                },
                installationWorkingPointQuery: staticPressureSubjectEditor.value === "FAN_ONLY" ? null : {
                    requestedInstallationAirFlow: airFlowEditor.value,
                    requestedInstallationStaticPressure: staticPressureEditor.value
                },
                installationSpecification: { // TODO REDUCE DUPLICATION WITH SIMULATION
                    crosswindDetails: crosswindEditor.value != null && crosswindEditor.value.metersPerSecond != null ? {metersPerSecond: crosswindEditor.value.metersPerSecond} : null,
                    diffuserDetails: diffuserEditor.value != null && diffuserEditor.value.angle != null && diffuserEditor.value.heightMeters != null ? {
                        angle: diffuserEditor.value.angle,
                        heightMeters: diffuserEditor.value.heightMeters
                    } : null,
                    fanApplicationTypeCode: applicationTypeEditor.value,
                    inletObstacleParameters: inletObstacleParamsEditor.value != null && inletObstacleParamsEditor.value.obstacleToImpellerArea != null && inletObstacleParamsEditor.value.obstacleDistanceToHousingDiameter != null ? {
                        obstacleDistanceToHousingDiameter_LToD: inletObstacleParamsEditor.value.obstacleDistanceToHousingDiameter,
                        obstacleToImpellerArea_ApToAc: inletObstacleParamsEditor.value.obstacleToImpellerArea
                    } : null,
                    outletObstacleParameters: outletObstacleParamsEditor.value != null && outletObstacleParamsEditor.value.obstacleToImpellerArea != null && outletObstacleParamsEditor.value.obstacleDistanceToHousingDiameter != null ? {
                        obstacleDistanceToHousingDiameter_LToD: outletObstacleParamsEditor.value.obstacleDistanceToHousingDiameter,
                        obstacleToImpellerArea_ApToAc: outletObstacleParamsEditor.value.obstacleToImpellerArea
                    } : null,
                    inletShapeId: inletShapeEditor.value,
                    operationalSetupCode: operationalSetupEditor.value,
                },
                manufacturingSpecification: { // TODO REDUCE DUPLICATION WITH SIMULATION
                    bladeManufacturingTypeCode: bladeManufacturingTypeEditor.value,
                    materialPreference: {
                        preferredConstructionMaterialCode: preferredConstructionMaterialEditor.value,
                        preferredFastenersMaterialCode: preferredFastenersMaterialEditor.value
                    }
                },
                mechanicalPreferences: { // TODO REDUCE DUPLICATION WITH SIMULATION
                    airflowRelativeClutchMountingSideCode: airflowRelativeClutchMountingSideEditor.value,
                    fanMountingOrientationCode: fanMountingOrientationEditor.value,
                    preferredSupportAndClutchDiscSideCode: supportAndClutchDiscSideEditor.value,
                    expectedImpellerHeight: impellerHeightEditor.value,
                    shaftDiameter: shaftDiameterEditor.value === null ? null : parseInt(shaftDiameterEditor.value),
                    shaftLength: shaftLengthEditor.value
                },
                softStartPresent: softStartPresentEditor.value === "true",
                realizeWithClutch: realizeWithClutchEditor.value === "true"


            }
        }

        if (
            (airFlowEditor.value != null) && // Simulation requires exact air flow
            (adjustableImpellerIdEditor.enabled && adjustableImpellerIdEditor.value != null) && // ADjustable impeller must be picked for simulation
            (simulationBladeAngleEditor.enabled && simulationBladeAngleEditor.value != null) &&
            dimensionsEditorFacade.dimensionDetails != null &&
            inletTemperatureEditor.value != null &&
            airDensityEditor.value != null &&
            speedEditorFacade.speedDetails !== "speed-autoselection" // No autoselection for RPM for simulation
        ) {
            simulationRequestJson = {
                demoAirFlow: airFlowEditor.value,
                fanFlowSpecification: {
                    adjustableImpellerId: adjustableImpellerIdEditor.value,
                    bladeAngle: parseFloat(simulationBladeAngleEditor.value),
                    fanDiameters: {
                        gap: dimensionsEditorFacade.dimensionDetails.bladeTipClearanceRatio,
                        impellerDiameter: dimensionsEditorFacade.dimensionDetails.impellerDiameter
                    },
                    fluidSpecification: {
                        density: airDensityEditor.value,
                        temperature: inletTemperatureEditor.value
                    },
                    rpm: speedEditorFacade.speedDetails.rpm
                },
                installationSpecification: {
                    crosswindDetails: crosswindEditor.value != null && crosswindEditor.value.metersPerSecond != null ? {metersPerSecond: crosswindEditor.value.metersPerSecond} : null,
                    diffuserDetails: diffuserEditor.value != null && diffuserEditor.value.angle != null && diffuserEditor.value.heightMeters != null ? {
                        angle: diffuserEditor.value.angle,
                        heightMeters: diffuserEditor.value.heightMeters
                    } : null,
                    fanApplicationTypeCode: applicationTypeEditor.value,
                    inletObstacleParameters: inletObstacleParamsEditor.value != null && inletObstacleParamsEditor.value.obstacleToImpellerArea != null && inletObstacleParamsEditor.value.obstacleDistanceToHousingDiameter != null ? {
                        obstacleDistanceToHousingDiameter_LToD: inletObstacleParamsEditor.value.obstacleDistanceToHousingDiameter,
                        obstacleToImpellerArea_ApToAc: inletObstacleParamsEditor.value.obstacleToImpellerArea
                    } : null,
                    outletObstacleParameters: outletObstacleParamsEditor.value != null && outletObstacleParamsEditor.value.obstacleToImpellerArea != null && outletObstacleParamsEditor.value.obstacleDistanceToHousingDiameter != null ? {
                        obstacleDistanceToHousingDiameter_LToD: outletObstacleParamsEditor.value.obstacleDistanceToHousingDiameter,
                        obstacleToImpellerArea_ApToAc: outletObstacleParamsEditor.value.obstacleToImpellerArea
                    } : null,
                    inletShapeId: inletShapeEditor.value,
                    operationalSetupCode: operationalSetupEditor.value,
                },
                manufacturingSpecification: {
                    bladeManufacturingTypeCode: bladeManufacturingTypeEditor.value,
                    materialPreference: {
                        preferredConstructionMaterialCode: preferredConstructionMaterialEditor.value,
                        preferredFastenersMaterialCode: preferredFastenersMaterialEditor.value
                    }
                },
                mechanicalPreferences: {
                    airflowRelativeClutchMountingSideCode: airflowRelativeClutchMountingSideEditor.value,
                    fanMountingOrientationCode: fanMountingOrientationEditor.value,
                    preferredSupportAndClutchDiscSideCode: supportAndClutchDiscSideEditor.value,
                    expectedImpellerHeight: impellerHeightEditor.value,
                    shaftDiameter: shaftDiameterEditor.value === null ? null : parseInt(shaftDiameterEditor.value),
                    shaftLength: shaftLengthEditor.value
                },
                softStartPresent: softStartPresentEditor.value === "true",
                realizeWithClutch: realizeWithClutchEditor.value === "true"
            }
        }


    }


    let simulationFanAdjustmentReference: SimulationFanAdjustmentReference | "not-available" = "not-available";
    if (adjustableImpellerIdEditor.value !== null && simulationBladeAngleEditor.value !== null) {
        simulationFanAdjustmentReference = {
            adjustableImpellerId: adjustableImpellerIdEditor.value,
            bladeAngle: parseFloat(simulationBladeAngleEditor.value)
        }
    }

    // Dirty stabilization to avoid sending request and then immediate cancellation before propopulation criteira is applied or other condition
    useEffect(() => {
        const t = setTimeout(() => {
            setStabilizationUnlocked(true);
        }, 500);
        return () => {
            clearTimeout(t);
        }
    }, [])


    const formElement = <>
        {collapsed ? <>
            <div className={"d-flex justify-content-end"} style={{position: "relative"}}>
                <div style={{position: "absolute", zIndex: "5"}}>
                    <button className={"btn btn-sm btn-secondary"} onClick={() => setCollapsed(p => !p)}>{t("Dostosuj kryteria")} <i
                        className="fas fa-chevron-down fa-lg"></i></button>
                </div>
                a
            </div>

        </> : null}
        {!collapsed ? <>
            <div className={"d-flex justify-content-end"} style={{position: "relative"}}>
                <div style={{position: "absolute", zIndex: "5"}} className={"d-flex gap-1 p-2"}>
                    <button className={"btn btn-sm btn-outline btn-outline-primary"} onClick={() => reset()}>
                        <i className="fas fa-undo "></i>
                    </button>
                    {variant === SelectionCriteriaVariant.GENERIC_FAN_SELECTION && <SelectionCriteriaUploader onUpload={setPrepopulationSelectionRequest}/>}
                    {variant === SelectionCriteriaVariant.GENERIC_FAN_SELECTION && selectionRequestJson != "incorrect-request" && <SelectionCriteriaDownloader criteria={selectionRequestJson}/>}
                    <button className={"btn btn-sm btn-outline btn-outline-primary"} onClick={() => setCollapsed(p => !p)}>
                        <i className="fas fa-chevron-up"></i>
                    </button>
                </div>
            </div>
            {actualForm}
        </> : null}

    </>;


    useEffect(() => {
        if (stabilizationUnlocked && selectionRequestJson !== "incorrect-request") {
            if (prepopulationSimulationRequest === null || (variant === SelectionCriteriaVariant.SPECIFIC_FAN_SIMULATION && collapsed === false)) {
                // Store valid criteria as last prefere
                const newPreferedCriteria = JSON.stringify(selectionRequestJson);
                console.log("Storing new criteria preference", selectionRequestJson);
                sessionStorage.setItem("preferredSelectionCriteria", newPreferedCriteria);
            } else {
                console.log("Predefined criteria exist - not storing new preference");
            }
        }
    }, [stabilizationUnlocked, JSON.stringify(selectionRequestJson)]);

    if (!stabilizationUnlocked) {
        return {
            simulationRequestJson: "incorrect-request",
            selectionRequestJsonOrIncorrect: "incorrect-request",
            formElement
        }
    } else {
        return {
            simulationRequestJson,
            selectionRequestJsonOrIncorrect: selectionRequestJson,
            formElement
        }
    }

}


export function CriteriaCard({title, children}: { title?: string, children: ReactNode }) {
    return <Card additionalBodyClassNames={"p-4"}>
        <>
            {title && <SelectionAspectHeader title={title}/>}
            {children}
        </>
    </Card>
}

function SelectionAspectHeader({title}: { title: string }) {
    return <div className="text-gray-400 fs-5">{title}</div>;
}

export function AspectSectionHeader({title}: { title: string }) {
    return <div className="text-gray-500 fs-6 text-center">{title}</div>;
}

export function LabeledEditor({label, editor, leftCols = 6, rightCols = 6}: {
    label: string,
    editor: Editor<any>,
    leftCols?: number,
    rightCols?: number
}) {
    return <div className={"row mb-2"}>
        <div className={"col-" + leftCols + " fw-bold pt-2"}
             style={{display: "flex", alignContent: "start", justifyContent: "right", flexWrap: "wrap"}}>{label}</div>
        <div className={"col-" + rightCols}>{editor.Component()}</div>
    </div>;
}

export function LabeledEditorlike({label, children, leftCols = 6, rightCols = 6}: {
    label: string,
    children: JSX.Element,
    leftCols?: number,
    rightCols?: number
}) {
    return <div className={"row mb-2"}>
        <div className={"col-" + leftCols + " fw-bold pt-2"}
             style={{display: "flex", alignContent: "start", justifyContent: "right", flexWrap: "wrap"}}>{label}</div>
        <div className={"col-" + rightCols}>{children}</div>
    </div>;
}

export function FlexedLabeledEditor({label, editor, leftCols = 6, rightCols = 6}: {
    label: string,
    editor: Editor<any>,
    leftCols?: number,
    rightCols?: number
}) {
    return <div className={"mb-2"}
                style={{display: "flex", alignContent: "start", justifyContent: "center", flexWrap: "nowrap"}}>
        <div className={"fw-bold pt-2 pe-2"}>{label}</div>
        <div style={{minWidth: "40%"}}>{editor.Component()}</div>
    </div>;
}

export function Row({children}: { children: ReactNode }) {
    return <div className={"row"}>{children}</div>
}

export function ColumnHalf({children}: { children: ReactNode }) {
    return <div className={"col-6"}>{children}</div>
}

export function useOptionalMeasurementEditor(standard: MeasurementStandard, initiallyEnabled: boolean = true, bonusElementsWhenEnabled: (editor: MeasureEditor) => ReactNode = (e) => <></>): OptionalEditor<MeasureEditorValue> {
    return useEditor<OptionalEditor<MeasureEditorValue>>(() => {
            const editor: MeasureEditor = createEditorForMeasurementStandard(standard);
            return new OptionalEditor<MeasureEditorValue>(
                editor,
                initiallyEnabled,
                (toggleEnablement) =>
                    <>
                        <MetronicMeasureEditorDummyWithButton fieldText={t("Autodobór")} buttonLabel={t("Zadaj")}
                                                              onButtonClick={toggleEnablement}/>
                    </>,
                (DelegatedEditorComponent, toggleEnablement) =>
                    <div className={"d-flex"}>
                        <div>
                            <DelegatedEditorComponent/>
                            {bonusElementsWhenEnabled(editor)}
                        </div>
                        <div className={"ps-3"}>
                            <button className={"btn btn-xxs btn-outline btn-outline-success p-2"}
                                    onClick={toggleEnablement}>{t("Auto")}
                            </button>
                        </div>
                    </div>
            );
        }
    )
}

export function useOptionalOneOfEditor(options: SelectionOption[], initialValue: string, initiallyEnabled: boolean = true): OptionalEditor<string> {
    return useEditor<OptionalEditor<string>>(() =>
        new OptionalEditor<string>(
            new OneOfEditor(
                options,
                undefined,
                initialValue
            ),
            initiallyEnabled,
            (toggleEnablement) =>
                <>
                    <MetronicMeasureEditorDummyWithButton fieldText={t("Autodobór")} buttonLabel={t("Zadaj")}
                                                          onButtonClick={toggleEnablement}/>
                </>,
            (DelegatedEditorComponent, toggleEnablement) =>
                <div className={"d-flex"}>
                    <div>
                        <DelegatedEditorComponent/>
                    </div>
                    <div className={"ps-3"}>
                        <button className={"btn btn-xxs btn-outline btn-outline-success p-2"}
                                onClick={toggleEnablement}>{t("Auto")}
                        </button>
                    </div>
                </div>
        )
    )
}

export function useDisableableOptionalMeasurementEditor(standard: MeasurementStandard, initiallyEnabled: boolean = true): OptionalEditor<MeasureEditorValue> {
    return useEditor<OptionalEditor<MeasureEditorValue>>(() => {

            const editor = createEditorForMeasurementStandard(standard);
            return new OptionalEditor<MeasureEditorValue>(
                editor,
                initiallyEnabled,
                (toggleEnablement) =>
                    <>
                        <MetronicMeasureEditorDummyWithButton fieldText={"" + editor.value} buttonLabel={t("Oblicz")}
                                                              onButtonClick={toggleEnablement}
                                                              unitDummyText={KILOGRAM_PER_CUBIC_METER_BASE_UNIT.displayText}/>
                    </>,
                (DelegatedEditorComponent, toggleEnablement) =>
                    <div className={"d-flex"}>
                        <div>
                            <DelegatedEditorComponent/>
                        </div>
                        <div className={"ps-3"}>
                            <button className={"btn btn-xxs btn-outline btn-outline-success p-2"}
                                    onClick={toggleEnablement}>{t("Oblicz")}
                            </button>
                        </div>
                    </div>
            );
        }
    )
}